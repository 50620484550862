.dashboardCard {
  transition: transform 0.2s ease;
  border: none;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.statsNumber {
  font-size: 2rem;
  font-weight: 600;
  color: #0d6efd;
}

.recentRace {
  transition: background-color 0.2s ease;
  padding: 1rem;
  border-radius: 8px;
}

.recentRace:hover {
  background-color: rgba(13, 110, 253, 0.03);
}

.winnerHighlight {
  color: #198754;
  font-weight: 500;
}

.progressWrapper {
  padding: 1rem;
  background: rgba(13, 110, 253, 0.03);
  border-radius: 8px;
}

.pointsTable {
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 1rem;
}

.pointsTable th {
  background: #e9ecef;
  padding: 0.75rem;
}

.cardTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: #495057;
}

.statsWrapper {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  margin-top: 1rem;
}

.progressSection {
  text-align: center;
  padding: 1rem;
  background: rgba(13, 110, 253, 0.03);
  border-radius: 8px;
  margin-top: 1rem;
}

.championCard {
  background: linear-gradient(135deg, #2a2a2a 0%, #1a1a1a 100%);
  border: none;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  color: white;
}

.championName {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: rgba(255, 215, 0, 0.1);
  border-radius: 8px;
}

.crownIcon {
  display: inline-block;
  padding: 15px;
  border-radius: 50%;
  background: rgba(255, 215, 0, 0.1);
  margin-bottom: 1rem;
}

.championStats {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.statItem {
  text-align: center;
  padding: 0 1rem;
}

.statValue {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffd700;
}

.statLabel {
  font-size: 0.9rem;
  color: #aaa;
  text-transform: uppercase;
}